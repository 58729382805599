
@import "./style.scss";

.farm-title {
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  line-height: 125%;
  color: #310707;
}

.intro-text {
  span {
    font-size: 16px;
    line-height: 24px;
    color: white;
  }
}

.wallet-connect-button {
  
}

input{
    background: transparent;
    border: 1px solid rgba(55,65,81,1) !important;
    border-radius: 5px;
    padding: 7px 10px;
}

.button{
    background-color: #34369d;
    color: #fff;
    border-radius: 10px;
    padding: 10px 15px 12px;
    font-weight: bold;
    font-size: 0.9em;
}

.wallet-set-up-link-box {
  background: #fcf2ef;
  box-shadow: 0px -1px 0px #f9e5df;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0;
  margin-top: 0px !important;

  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: rgb(171, 109, 119);
  }
}
.farm-top-title {
  
  font-weight: 400 !important;
  line-height: 1.2;
  letter-spacing: -0.03em;
  font-size: 32px;
  line-height: 40px;
  padding-right: 20%;
  @media screen and (min-width: 992px) {
    line-height: 159%;
    font-size: 40px;
    padding-right: 0;
  }
}

.stake-card-container {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    display: inline-flex;
  }
}

.stake_card {
  border: 6px solid rgba(189, 209, 0, 1);
  margin-right: 25px !important;
  margin-bottom: 24px !important;
 
  h5 {
    
    font-weight: 400 !important;
    color: #000;
    font-size: 24px;
    letter-spacing: -0.03em;
  }
  small {
    color: #977d83;
    font-weight: 400 !important;
    letter-spacing: 0.05em;
    font-size: 12px;
  }
  .pool-info {
    color:  #F5F5F5;
  }
  
  .card-body {
    padding-bottom: 0 !important;
  }
  .token-info {
    padding-top: 12px !important;
    h6 {
      font-weight: 400 !important;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      strong {
        
        font-weight: 400 !important;
        font-size: 1px;
        line-height: 25px;
        color: #000;
        letter-spacing: -0.02em;
      }
    }
    .value-text {
      font-size:12px;
    }
  }
  @media screen and (max-width: 992px) {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    margin-right: 0 !important;
  }
}

.stake-info {
  @media screen and (max-width: 680px) {
    .text-left {
      padding-left: 0;
      width: 45%;
    }
  }
}

.kawa-footer {
  .formlabel {
    letter-spacing: 0;
  }
  a,
  a:link,
  a:visited {
    text-decoration: none;
    color: rgb(171, 109, 119);
    font-weight: 400 !important;
  }
  a span {
    letter-spacing: 0;
    font-weight: 400 !important;
    line-height: 1;
  }
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
    min-height: 150px;
    .text-left,
    .text-right {
      text-align: center !important;
    }
  }
}

.card-listed {
  h2 {
    font-size: 28px;
    line-height: 35px;
    font-weight: 400 !important;
    color: rgba(105, 38, 38, 1);
    letter-spacing: -0.03em;
  }
}

.form-listtoken {
  background-color: #fefbfb;
  h1 {
    color: rgba(83, 4, 30, 1);
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.03em;
    font-weight: 400 !important;
    @media screen and (min-width: 992px) {
      font-size: 40px;
      line-height: 50px;
    }
  }
  p {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 24px;
  }
  .form-group {
    .form-control {
      color: #000;
      font-size: 16px;
      font-weight: 400 !important;
      outline: none;
      box-shadow: none;
      &::-webkit-input-placeholder {
        color: #a4a4a4;
      }

      &:-ms-input-placeholder {
        color: #a4a4a4;
      }

      &::placeholder {
        color: #a4a4a4;
      }
      &:active,
      &:focus {
        border: 1px solid #ff4848;
      }
    }
  }
}

.successModal {
  p {
    font-size: 16px;
    line-height: 24px;
    color: #543939;
    font-weight: 400 !important;
  }
  h2 {
    color: rgba(83, 4, 30, 1);
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.03em;
    font-weight: 400 !important;
    margin: 12px 0;
  }
}
