@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600&display=swap');

body {
  overflow-x: hidden;
  width: 100%;
  background-color: #000;
  font-family: 'Barlow', Inter, sans-serif !important;
}


.color-gray {
  color: #8a8a8a !important;
}

.input-group-prepend {
  .card_stake_text {
    button {
      height: 32px;
      line-height: 30px;
      font-size: 13px;
      text-transform: uppercase;
      padding: 0 12px;
    }
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background: none;
}

.form-control:disabled {
  background: transparent;
}

input[type="text"]:disabled {
  color: #000;
}

h1 {
  font-size: 2.3rem;
  @media (max-width: 1200px) {
    font-size: 1.8rem;
  }
}

.main_layout {
  background-color: black !important;
  min-height: 100vh;
}

.main-dash-container {
  padding-top: 60px;
  
}

.header {
  background-color: #25224e;
}

hr {
  border-top: 1px solid rgba(189, 28, 59, 0.2);
}

.text_white {
  color: white;
  &:hover {
    color: white !important;
  }
}

.btn {
  border-radius: 10px;
  &.successModalButton {
    font-size: 0.9em;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    letter-spacing: 0.05em;
  }
  &.loaderButton {
    font-size: 0.9em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
  &.addMore,
  &.withDrawButton {
    height: 40px;
    font-size: 0.9em;
  }
}

.btn_white {
  color: #141132;
  background-color: white;
  border-radius: 7px;
  font-weight: 700;
}

.app_secondery {
  background-color: #211d40;
}

.line_side {
  border-right: 5px solid #565bb5;
  @media (max-width: 768px) {
    border-right: none;
  }
}

.icon_round {
  position: absolute;
  background-color: #141132;
  border: 10px solid #211d40;
  display: flex;
  width: 150px;
  height: 150px;
  top: 50%;
  right: 0%;
  border-radius: 200px;
  transform: translate(-35%, -50%);
  @media (max-width: 1600px) {
    width: 130px;
    height: 130px;
  }
}

.feature_img {
  max-width: 90%;
  padding-right: 4em;
}

.roadmap_card {
  background-color: #141132;
  border-radius: 25px;
  min-height: 250px;
}

.icon_right,
.icon_left {
  .icon_round {
    position: absolute;
    background-color: #141132;
    border: 10px solid #211d40;
    display: flex;
    width: 150px;
    height: 150px;
    top: 50%;
    left: -5%;
    border-radius: 200px;
    transform: translate(-50%, -50%);
  }
  .icon_line {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
  }
}

.icon_round_left {
  position: absolute;
  background-color: #141132;
  border: 10px solid #211d40;
  display: flex;
  width: 150px;
  height: 150px;
  top: 50%;
  right: 0;
  border-radius: 200px;
  transform: translate(60%, -50%);
}

.icon_top {
  .icon_round {
    position: absolute;
    background-color: #141132;
    border: 10px solid #211d40;
    display: flex;
    width: 150px;
    height: 150px;
    top: 0;
    left: 50%;
    right: 50%;
    border-radius: 200px;

    transform: translate(-50%, -50%);
  }
}

.icon_line {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-55%, -50%);
}

.icon_line_roadmap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.marquee_class {
  background-color: #0b0920;
  color: white;
  font-weight: 600;
  font-size: 1.5em;
}

.card_div {
  background-color: #141132;
  padding: 2.5em;
  border-radius: 25px;
}

.icon_over {
  background-color: #25224e;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.intro_section {
  min-height: 80vh;
}

.adviser_section {
  background-color: #141132;
  border-radius: 25px;
  min-height: 200px;
  // padding: 3em;

  .adviser_card {
    background-color: #25224e;
    padding: 2.5em;
    border-radius: 25px;
  }
}

.chart_sec {
  background-color: #25224e;
  border-radius: 25px;
}

.chart_container {
  min-height: 50%;
  min-width: 50%;
  max-height: 100%;
  max-width: 70%;

  @media (max-width: 767px) {
    min-height: 200px;
    min-width: 200px;
  }
}

.tokenomics_side {
  .chart_sec {
    background-color: #25224e;
    border-radius: 25px;

    .round_div {
      height: 170px;
      width: 170px;
      border-radius: 100px;
      border: 8px solid rgba(255, 255, 255, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .price_tag {
      background-color: white;
      color: #141132;
      border-radius: 15px;
      font-size: 1em;
      font-weight: bold;
    }
  }
  .lable_top {
    h3 {
      background-color: #141132;
      min-width: 200px;
    }
  }
}

.contact_us_light {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
}

.contact_us_dark {
  background-color: #1b1941;
  border-radius: 15px;
}

.submit_footer {
  background-color: #141132;
  color: white;
  border: none;
}

.year_switch {
  background-color: #141132;
  border-radius: 50px;
}

.feature_card {
  width: 75%;
  @media (max-width: 992px) {
    width: 100%;
  }
}

.pre_block {
  .app_secondery {
    padding-bottom: 200px;
    border-radius: 0 0 15px 15px;
    @media (max-width: 768px) {
      padding-bottom: 100px;
    }
  }
}

.counter_block {
  height: 0;
  color: #141132;
  width: fit-content;
  .counter_white {
    border-radius: 20px;
    .text_large {
      font-size: 4.5rem;
      @media (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
  transform: translate(-0%, -230px);
  @media (max-width: 767.98px) {
    transform: translate(-0%, -150px);
  }
}

.border_radius {
  border-radius: 15px;
}

.bg_gray {
  background-color: #3d3b51;
}

.w-fill-available {
  width: -webkit-fill-available;
}

.w-fit-content {
  width: fit-content;
}

.modal-90w {
  width: 90%;
}

.theme_light {
  background-color: #efefef;
  color: #4b4b4b;
  .card_sec {
    background-color: white;
  }
  .farm_card {
    .bg_app_dark {
      background-color: #1e1b40;
    }
    .app_border {
      border: 2px solid #1e1b40;
      color: #1e1b40;
      background-color: transparent;
    }

    .bg_harvest {
      background-color: #e9e9e9;
    }
    .text_app {
      color: #1e1b40;
    }
  }
}

.theme_dark {
  background-color: #1d1d1d;
  color: white;
  .card_sec {
    background-color: #484848;
  }
  .farm_card {
    .bg_app_dark {
      background-color: #403894;
    }
    .app_border {
      border: 2px solid white;
      color: white;
      background-color: transparent;
    }

    .bg_harvest {
      background-color: #333333;
    }
    .text_app {
      color: white;
    }
    .deposit_card {
      background-image: url(../assets/card-bg.png);
    }
    .harvest_card {
      background-image: url(../assets/card-bg.png);
    }
  }
  .submit_footer_darh {
    background-color: #403894;
    color: white;
    border: none;
  }
}

.custom_input {
  border: 2px solid #141132;
  border-radius: 7px;
  background-color: transparent !important;
}

.bg_transprent {
  background-color: transparent;
}

.custome_normal_modal_light {
  .modal-header {
    background-color: #25224e;
    border-radius: 13px 13px 0 0;
    button {
      color: white;
    }
  }
  .modal-content {
    border-radius: 15px;
    button {
      color: #707070;
    }
  }
  .deposit_card {
    background-image: url("../assets/card-bg.png");
    background-color: #25224e;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    color: white;
    min-height: 150px;
    .text_app {
      color: #1e1b40;
    }
  }

  .harvest_card {
    background-image: url("../assets/card-bg.png");
    background-color: #25224e;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    color: white;
    min-height: 60px;
    .text_app {
      color: #1e1b40;
    }
  }

  .app_border {
    border: 2px solid #1e1b40;
    color: #1e1b40;
    background-color: transparent;
  }

  .bg_app_dark {
    background-color: #25224e;
  }
}

.custome_normal_modal_dark {
  color: white;
  .modal-header {
    background-color: #403894;
    border-radius: 13px 13px 0 0;
    button {
      color: white;
    }
  }
  .modal-content {
    background-color: #1f1f1f;
    // background-color: #333333;
    border-radius: 15px;
    button {
      color: #707070;
    }
  }
  .deposit_card {
    background-image: url("../assets/card-bg.png");
    background-color: #25224e;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    color: white;
    min-height: 150px;
    .text_app {
      color: #1e1b40;
    }
  }
  .harvest_card {
    background-image: url("../assets/card-bg.png");
    background-color: #25224e;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 15px;
    color: white;
    min-height: 60px;
    .text_app {
      color: #1e1b40;
    }
  }
  .app_border {
    border: 2px solid white;
    color: white;
    background-color: transparent;
  }

  .bg_app_dark {
    background-color: #25224e;
  }
  .h_title {
    color: white;
  }

  .bg_harvest {
    background-color: #333333;
  }
  .text_app {
    color: white;
  }
  .custom_input {
    border: 2px solid #403894;
    border-radius: 7px;
    background-color: transparent !important;
    color: white;
  }
}

.card_image {
  max-width: 100px !important;
  max-height: 100px;
  height: auto;
  width: auto;
}
.stake_card {
 
  min-width: 18rem;
  width: 22rem !important;
  max-width: 32rem !important;
  border-top: 2px solid white !important;
  border-left: 2px solid white !important;
  border-right: 2px solid #bdd100 !important;
  border-bottom: 2px solid #bdd100 !important;
  height: auto;
  min-height: 550px !important;
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .headerdropdown {
    width: 195px !important;
  }
  .prePended {
    background: #fcdfe9 !important;
    display: none;
  }
}

.token-info {
  small {
    line-height: 1;
  }
}

.cardBox {
  // height:120px;
  background: #000;
  
  
}

.card_stake_text {
  color: #F5F5F5;
}
.cardButton {
  color: #136f1c;
  border-radius: 8px;
  border: 1px solid #8cd594;
}
.tagKawa {
  background: #fef1f4;
  border: 1px solid #ffa8ba;
  box-sizing: border-box;
  border-radius: 0;
  color: #ff2e59;
}
.cardBodyColor {
  // background-color: #FAFAFA;
  // background-color: #fffaf9;
}

.getInTouchButton {
  background: rgba(251, 0, 0, 0.1);
  color: #903434;
  border: none;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  padding: 0 24px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  &:focus {
    box-shadow: none !important;
    background-color: #ffa6a6 !important;
    color: #903434 !important;
  }
  &:focus-within,
  &:focus-visible {
    background-color: #ffa6a6 !important;
    box-shadow: none !important;
    color: #903434 !important;
  }
  &:active {
    background-color: #ffa6a6 !important;
    box-shadow: none !important;
    color: #903434 !important;
  }
  &:hover {
    background: rgba(255, 166, 166, 0.7);
    color: #903434;
    border: none;
  }
}

.withDrawButton {
  background: #fae2e9 !important;
  border: 1px solid #ffa8ba;
  border-radius: 8px;
  color: #f7215e;
  &:hover {
    color: #ff2e59;
    border: 1px solid #ffa8ba !important;
    background: #fae2e9 !important;
  }
  @media screen and (max-width: 680px) {
    margin-bottom: 12px;
  }
}
.addMore {
  background: linear-gradient(90deg, #f1116e 0%, #f72160 100%);
  border: none;
  color: #ffffff;
}

.addMore:hover {
  background: linear-gradient(90deg, #fe207c 0%, #ff2e6b 100%);
}

.formlabel {
  letter-spacing: 0.05em;
  color: #642828;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.footerClass {
  @media only screen and (max-width: 600px) {
    text-align: center;
  }
}

.loginGradiant {
  @media screen and (max-width: 992px) {
    .col-lg-12 {
      padding: 0 30px;
    }
  }
}

.loginBox {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 0px 88px rgba(138, 57, 69, 0.1);
  border-radius: 0;
  margin-bottom: 0px !important;
  .p-4.text-center.row {
    padding-bottom: 0 !important;
  }
  h3 {
    font-size: 24px;
    @media screen and (min-width: 992px) {
      font-size: 32px;
    }
  }
}

.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  .svg-kawa {
    width: 100%;
    max-width: 105px;
    margin: 0;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}

.bs-popover-bottom > .arrow {
  left: -4px !important;
  @media screen and (min-width: 992px) {
    left: -6px !important;
  }
}

.navbar-collapse {
  @media screen and (max-width: 992px) {
    margin-right: 0 !important;
  }
}

.walletsetup { 
  font-family: 'Eurostile Extended', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  /* identical to box height, or 17px */
  color: #977d83;
  text-align: center;
  letter-spacing: 0.01em;
  text-decoration-line: underline;
  margin-bottom: 0 !important;
  padding: 5px;
}

p.walletsetup {
  font-weight: 400;
}

.headerdropdown {
  
  border-radius: 8px;
  margin-bottom: 0;
  .input-group-text {
    background-color: #fcdfe9;
    border: 0 none;
  }
}
.dropDownMenuBox {
  width: 221px;
  height: 164px;
  background: #ffffff;
  border: 1px solid #eae5e7;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(70, 24, 40, 0.1);
  border-radius: 8px;
}
.loaderButton {
  background: transparent !important;
  border-radius: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 2px;
  border: none;
  &:focus {
    border: none;
  }
}
.successModalButton {
  background: #ffd4d2;
  color: #53041e;
  border-radius: 8px;
  border: none;
  &:hover {
    background: #ffd4d2;
    border: none;
    color: #53041e;
  }
  &:focus {
    background: #ffd4d2;
    border: none;
    color: #53041e;
  }
  &:focus-within {
    background: #ffd4d2;
    border: none;
    color: #53041e;
  }
}
.successModal {
  background: #fff4f3;
  height: 534px;
  box-shadow: 20px 20px 50px 10px #f9e5df inset;
  border-radius: 0px;
  display: flex;
  align-self: center;
  align-items: center;
  @media only screen and (max-width: 600px) {
    height: 450px;
    width: 400px !important;
  }
}

.popoverClass {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  width: 221px;
  height: 283px;
  background: #ffffff;
  border: 1px solid #eae5e7;
  box-sizing: border-box;
  box-shadow: 0px 4px 24px rgba(70, 24, 40, 0.1);
  border-radius: 8px;
}

.logo-image-container {
  padding-right: 0;
  padding-left: 0;
  img {
    width: 64px;
    height: auto;
    @media screen and (min-width: 992px) {
      width: 110px;
    }
  }
}

.svg-kawa {
  width: 100%;
  max-width: 170px;
  margin: 12px auto 24px;
  display: block;
  @media screen and (min-width: 992px) {
    max-width: 390px;
    margin: 30px auto;
  }
}


.border-emboss {
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
}

.border-deboss {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
}
